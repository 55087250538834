#login-parent {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#login-card {
  height: 50vh;
  width: 50vw;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  
  justify-content: center;
}

