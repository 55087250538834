.select-wrapper {
    position: relative;
  }
  
  .select-wrapper::after {
    content: "▼";
    font-size: 1rem;
    top: 6px;
    right: 13px;
    position: absolute;
  }